import { useEffect } from 'preact/hooks';
import { Icon } from './Icon';
import { Pill } from './Pill';
import { useSignal } from '@preact/signals';
import { cn } from 'utils/cn';

export function ScrollUp() {
	const isVisible = useSignal(false);
	const scrollUp = () => {
		globalThis.window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	useEffect(() => {
		const eventOptions: AddEventListenerOptions = { passive: true };

		const onScroll = () => {
			isVisible.value = globalThis.window.scrollY > 300;
		};

		globalThis.window.addEventListener('scroll', onScroll, eventOptions);

		return () => {
			globalThis.window.removeEventListener('scroll', onScroll, eventOptions);
		};
	}, []);

	if (!isVisible.value) {
		return null;
	}

	return (
		<Pill
			pillType="faded"
			onClick={scrollUp}
			className={cn(
				'bg-white fixed bottom-5 right-5 px-2.5 pb-1 border-none shadow-sm rounded-full z-pageFloater',
			)}
		>
			<Icon type="arrow_upward" className="text-red" />
		</Pill>
	);
}
